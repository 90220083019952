<template>
  <div class="container">
    <!-- 头部信息框 start -->
    <div class="header_bg">
      <div class="header_detail">
        <div class="detail_top">
          <img class="user_avatar" :src="userInfo.head" />
          <!-- <i class=""></i> -->
          <div class="user_info">
            <p>Hi，{{ userInfo.name }}</p>
            <p>这是你来到铜仁市阳光志愿者协会的第{{ userInfo.day }}天</p>
          </div>
        </div>
        <div class="detail_bottom"></div>
      </div>
    </div>
    <!-- 头部信息框 end -->

    <!-- 主体列表 -->
    <listTitle style="padding-left: 0.15rem" title="爱心救助" />

    <CardList v-if="id" />
    <div class="bah"><p><a target="_blank" href="https://beian.miit.gov.cn/">黔ICP备2024033211号-1</a></p></div>

    <!-- ```````````````tabbar`````````````````````` -->
    <TabBar />
  </div>
</template>

<script>
import TabBar from '@/components/pageTabbar.vue';
import listTitle from '@/components/listTitle.vue';
import CardList from '@/components/cardList.vue';
import { List } from 'vant';

import { getUserInfoApi, getHomeListApi } from '@/api/home';

export default {
  components: {
    TabBar,
    listTitle,
    CardList,
    [List.name]: List
  },
  data() {
    return {
      loading: false,
      finished: false,
      userInfo: {},
      id: '',
      sum: ''
    };
  },
  mounted() {
    let userId = JSON.parse(localStorage.getItem('userInfo')) || '';
    if (userId == '') {
      this.getUserInfo();
    } else {
      this.id = userId.id;
      this.getHomeList();
    }
  },
  methods: {
    // async getListData() {}
    // 获取用户信息
    async getUserInfo() {
      let query = this.$route.query;
      let type = query.type || '';
      let data = query || '';
      let r = await getUserInfoApi(type, data);
      // this.userInfo = r.data;
      this.id = r.data.id;
      localStorage.setItem('userInfo', JSON.stringify(r.data));
      // 获取首页头部信息已经列表数据
      this.getHomeList();
    },

    // 获取页面头部信息
    async getHomeList() {
      let userId = JSON.parse(localStorage.getItem('userInfo')).id || '';
      // let userId = '1';
      let parmas = {
        id: userId,
        loadPage: '1'
      };
      let r = await getHomeListApi(parmas);
      // r.data.user_info.head = this.$baseUrl + r.data.user_info.head;
      let userDetailInfo = r.data.user_info;
      userDetailInfo.contribution = r.data.user_info.contribution;
      localStorage.setItem('userDetailInfo', JSON.stringify(userDetailInfo));
      this.userInfo = r.data.user_info;
      this.sum = r.data.sum;
    }
  }
};
</script>

<style lang="scss" scoped>
.bah {
  width: 100%;
  font-size: 14px;
  display: flex;
  color: #969799;
  justify-content: center;
}
.container {
  width: 100%;
  height: calc(100% - 0.5rem);
  overflow-y: auto;
}
.header_bg {
  width: 100%;
  height: 1.6rem;
  background: url('../../public/image/index_background.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.59rem;
  .header_detail {
    font-size: 12px;
    width: 95%;
    box-sizing: border-box;
    height: 1.16rem;
    background: url('@/image/home/detail_bg.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 0.88rem;
    padding: 0.2rem;
    box-shadow: 0px 2px 10px 1px rgba(112, 24, 0, 0.06);
  }
  .user_avatar {
    display: inline-block;
    width: 0.44rem;
    height: 0.44rem;
    border-radius: 50%;
    // background: url('@/image/home/user.png') no-repeat;
    background-size: 100% 100%;
    margin-right: 10px;
  }
  .detail_top {
    display: flex;
    align-items: center;
    .user_info :first-child {
      font-size: 16px;
      color: #333333;
    }
    .user_info :last-child {
      font-size: 12px;
      color: #999999;
    }
  }
  .detail_bottom {
    font-size: 12px;
    border-top: 2px dashed #000;
    margin-top: 15px;
    text-align: center;
    color: #998a8a;
    padding-top: 10px;
  }
}

// 列表样式

.card_list {
  font-size: 14px;
  box-sizing: border-box;
  padding: 0 15px;
}

.list_item {
  .item_pic {
    img {
      width: 100%;
      height: 1.94rem;
      background: no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
