import Vue from 'vue';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.prototype.$baseUrl = 'https://backstage.sunshinengo.com';
Vue.prototype.$webUrl = 'https://www.sunshinengo.com';

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
